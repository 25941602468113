import React from "react";

const Middle = () => {
	return (
		<>
			<div className="bg-white m-5">
				<h4 className="text-sm font-bold text-gray-500 font-mono text-center">
					Professional Experience
				</h4>
				<>
					<div className="overflow-x-auto">
						<ul className="timeline timeline-snap-icon timeline-vertical lg:timeline-horizontal">
							<li className="w-full lg:w-1/2">
								<div className="timeline-middle">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										className="h-5 w-5"
									>
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<div className="timeline-start md:text-end mb-10">
									<time className="font-mono italic">April 2022 - Present </time>
									<div className="text-lg font-black">
										Backend Developer @ Indicsoft Technologies Pvt Ltd (Noida,Sector-132)
									</div>
									{/* <p className="break-keep">
										The Apple Macintosh—later rebranded as the Macintosh 128K—is
										the original Apple Macintosh personal computer. It played a
										pivotal role in establishing desktop publishing as a general
										office function. The motherboard, a 9 in (23 cm) CRT
										monitor, and a floppy drive were housed in a beige case with
										integrated carrying handle; it came with a keyboard and
										single-button mouse.
									</p> */}
								</div>
								<hr />
							</li>
							<li className="w-full lg:w-1/2">
								<hr />
								<div className="timeline-middle">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										className="h-5 w-5"
									>
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<div className="timeline-end mb-10">
									<time className="font-mono italic">Oct 2021- Mar 2022</time>
									<div className="text-lg font-black">
										Software Engineer Intern @ Indicsoft Technologies Pvt Ltd
									</div>
									{/* iMac is a family of all-in-one Mac desktop computers designed
									and built by Apple Inc. It has been the primary part of Apple's
									consumer desktop offerings since its debut in August 1998, and
									has evolved through seven distinct forms */}
								</div>
								<hr />
							</li>
						</ul>
					</div>
				</>
			</div>
		</>
	);
};

export default Middle;
