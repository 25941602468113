import React from "react";

const Right = () => {
	return (
		<>
			<div className="bg-gray-50 rounded-md m-5">
				<div className="flex justify-center items-center py-2">
					{/* Email */}
					<p className="text-sm font-mono font-medium p-2 flex items-center gap-2">
						Email <img src="/email-svgrepo-com.svg" className="h-4 w-4" />
					</p>
					<a
						className="text-sm text-gray-500 font-extralight px-2"
						href="mailto:kasifraza910@gmail.com"
					>
						kasifraza910@gmail.com
					</a>
				</div>
				<div className="flex justify-center items-center py-2">
					{/* Mobile Number */}
					<p className="text-sm font-mono font-medium p-2 flex items-center gap-2">
						Contact <img src="/phone-book-svgrepo-com.svg" className="h-4 w-4 " />
					</p>

					<a
						className="text-sm text-gray-500 font-extralight px-2"
						href="tel:+91 9873287018"
					>
						+91 9873287018
					</a>
				</div>
				<div className="flex justify-center items-center py-2">
					{/* Location */}
					<p className="text-sm font-mono font-medium p-2 flex items-center gap-2">
						<img src="/home-1-svgrepo-com.svg" className="h-4 w-4 " />
					</p>
					<p className="text-sm text-gray-500 font-extralight px-2">
						Jasola,New Delhi (110025),India
					</p>
				</div>
				<div className="flex justify-center items-center py-2">
					{/* Work Experience */}
					<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
						Total Work Exp:
					</p>
					<p className="text-sm text-gray-500 font-extralight px-2">2 Years & 8 Months</p>
				</div>
				<div className="flex justify-center items-center py-2">
					{/* Qualification */}
					<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
						B.Tech(CSE)
					</p>
					<p className="text-[10px] text-gray-500 font-extralight px-2">
						2018-2022 <br />
						(Maharishi Dayanand University)
					</p>
				</div>
				<div className="flex justify-center items-center py-2">
					{/* Linkedin */}
					<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
						<img src="/linkedin-1-svgrepo-com.svg" className="h-8 w-8" />
					</p>
					<a
						className="text-[10px] text-blue-500 font-extralight px-2"
						href="https://www.linkedin.com/in/kasif-raza-070538170/"
						target="_blank"
					>
						https://www.linkedin.com/in/kasif-raza-070538170/
					</a>
				</div>
			</div>
			<div className="bg-white flex justify-center">
				<div className="carousel carousel-center p-4 space-x-4 rounded-box bg-white">
					<div className="carousel-item w-[95%]">
						<div className="bg-gray-100 rounded-box">
							<span className="text-sm font-mono  text-red-900  px-2">
								No Code Low Code Platform
								<br /> using Nocobase(OpenSource)
							</span>
							<p className="text-[12px] bg-gray-200 rounded-xl font-extralights px-2 py-2 text-blue-900">
								Developing a No Code Low Code platform utilizing Nocobase (Open
								Source) to enable rapid product development and deployment through
								custom plugin creation.
							</p>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Key Responsibilities:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Built and integrated custom APIs to extend platform
										functionality.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Created custom plugins for seamless payment gateway
										integration.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Developed custom carousels to enhance user interface and
										experience.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Optimized the platform for ease of use, allowing
										non-technical users to create and manage applications
										efficiently.
									</li>
								</ul>
							</div>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Technologies Used:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Nocobase(OpenSource).
									</li>
									<li className="px-2 text-[12px] text-blue-950">PostGres.</li>
									<li className="px-2 text-[12px] text-blue-950">React</li>
									<li className="px-2 text-[12px] text-blue-950">
										Razorpay (for payment gateway integration)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="carousel-item w-[95%]">
						<div className="bg-gray-100 rounded-box">
							<span className="text-sm font-mono  text-red-900  px-2">
								Hajj and Umrah Package Booking Platform
							</span>
							<p className="text-[12px] bg-gray-200 rounded-xl font-extralights px-2 py-2 text-blue-900">
								Collaborated on the development of a Hajj and Umrah package booking
								platform designed for agents to sell packages and for pilgrims to
								book their journeys. The platform leverages modern web technologies
								to provide a seamless booking experience.
							</p>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Key Responsibilities:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Developed RESTful APIs using NestJS to handle core
										functionalities such as user authentication, package
										management, and booking processes.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Integrated Razorpay payment gateway to facilitate secure and
										efficient transactions.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Contributed to the admin panel built with Next.js, enabling
										agents to manage packages, view bookings, and track sales
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Utilized MongoDB for efficient data storage and retrieval,
										ensuring robust and scalable database solutions.
									</li>
								</ul>
							</div>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Technologies Used:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">NestJS.</li>
									<li className="px-2 text-[12px] text-blue-950">MongoDB.</li>
									<li className="px-2 text-[12px] text-blue-950">Next.js</li>
									<li className="px-2 text-[12px] text-blue-950">
										Razorpay (for payment gateway integration)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="carousel-item w-[95%]">
						<div className="bg-gray-100 rounded-box">
							<span className="text-sm font-mono  text-red-900  px-2">
								Notification Service System (Cross-Application Communication)
							</span>
							<p className="text-[12px] bg-gray-200 rounded-xl font-extralights px-2 py-2 text-blue-900">
								Developed a robust messaging service system to handle
								cross-application communication, prioritizing and managing
								notifications across multiple applications. The system is designed
								for rapid deployment and integration into various services,
								facilitating efficient and reliable messaging for tasks such as OTP
								verification and order confirmations.
							</p>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Key Responsibilities:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Contributed to the development of a scalable backend system
										using Kafka as a message broker to handle message queues and
										ensure reliable delivery.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Implemented Twilio for sending mobile SMS notifications,
										ensuring timely and efficient communication.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Developed email notification functionality using Nodemailer
										for sending various types of emails such as OTPs and order
										confirmations.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Utilized YugabyteDB, built on top of PostgreSQL, for
										efficient and scalable data storage and retrieval.
									</li>
								</ul>
							</div>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Technologies Used:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Kafka (Message Broker).
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Twilio (SMS Sending).
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Nodemailer (Email Sending).
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										YugabyteDB (Database)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="carousel-item w-[95%]">
						<div className="bg-gray-100 rounded-box">
							<span className="text-sm font-mono  text-red-900  px-2">
								Media Service
							</span>
							<p className="text-[12px] bg-gray-200 rounded-xl font-extralights px-2 py-2 text-blue-900">
								Independently developed a media service application for internal
								company use. This service allows users to upload media files to
								either AWS S3 or Cloudinary through a single unified endpoint,
								abstracting the storage backend from the user.
							</p>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Key Responsibilities:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Designed and developed multiple RESTful APIs using
										Express.js to handle media upload requests.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Implemented logic to dynamically manage and direct media
										uploads to AWS S3 or Cloudinary based on backend
										configurations.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Ensured seamless user experience by providing a single
										endpoint for media uploads, irrespective of the storage
										service used.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Utilized AWS S3 for scalable and reliable storage of media
										files.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Integrated Cloudinary for advanced media management
										capabilities, including image optimization and
										transformation.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Developed robust handling mechanisms to manage and monitor
										media uploads to both AWS S3 and Cloudinary.
									</li>
								</ul>
							</div>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Technologies Used:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Express.js (Backend Development).
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										AWS S3 (Media Storage)
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Cloudinary (Media Management)
									</li>
									<li className="px-2 text-[12px] text-blue-950">Node.js</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="carousel-item w-[95%]">
						<div className="bg-gray-100 rounded-box">
							<span className="text-sm font-mono  text-red-900  px-2">
								Swachh Bharat Mission Platform
							</span>
							<p className="text-[12px] bg-gray-200 rounded-xl font-extralights px-2 py-2 text-blue-900">
								Contributed to the development of the Swachh Bharat Mission (SBM)
								platform, an initiative under the Ministry of Housing and Urban
								Affairs (MoHUA). The platform includes multiple roles such as Urban
								Local Bodies (ULB), State authorities, vendors, and PMU. The project
								focuses on managing and evaluating various cleanliness campaigns and
								challenges across India.
							</p>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Key Responsibilities:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Developed multiple RESTful APIs for various modules
										including the "Swachhata Hi Seva" campaign, "Clean Toilet
										Challenge," and SBM partners.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Utilized existing Single Sign-On (SSO) for authentication to
										ensure secure access to the platform's services.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Implemented services to manage campaign data submission by
										ULBs, including validation and processing.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Developed functionalities for ULBs to submit campaign data,
										which is then evaluated by designated evaluators.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Implemented a system for evaluators to verify the
										authenticity of the submitted data.
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										Facilitated PMU's role in approving validated data and
										determining awards and funding for states based on campaign
										performance.
									</li>
								</ul>
							</div>
							<div className="flex justify-center  rounded-md px-2 my-2">
								<p className="">Technologies Used:</p>
							</div>
							<div className="flex justify-center bg-slate-50 rounded-md mx-2 my-2">
								<ul className="list-disc list-inside ">
									<li className="px-2 text-[12px] text-blue-950">
										Express.js (Backend Development).
									</li>
									<li className="px-2 text-[12px] text-blue-950">
										AWS S3 (Media Storage)
									</li>
									<li className="px-2 text-[12px] text-blue-950">Node.js</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Right;
