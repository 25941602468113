import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import Right from './components/Right';
import Middle from './components/Middle';

function App() {
  return (
    <div className=" bg-[#F8F9FA] h-[100%] w-[100vw] py-[10px] px-[10px] lg:px-[100px]">
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-3'>

      <HomePage/>
      <div className=' col-span-2 bg-white rounded-xl'>
        <img src='/tech.gif' className='rounded-[20px] h-[300px] w-full border-gray-200 border hidden lg:block'/>
        <Middle/>
      </div>
      <div className='bg-white rounded-lg'>
        <Right/>
      </div>
      </div>
    </div>
  );
}

export default App;
